.whatmakesuscard {
  width: 304px;
  height: 345px;
  border-radius: 14px;
  box-shadow: 4px 0px 36px 2px #535b622e;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.whatmakesuscard-title {
  font-family: Poppins;
  font-size: var(--c14-12);
  font-weight: 600;
  line-height: var(--c21-18);
  text-align: left;
  color: #2f363f;
  margin-top: 24px;
  margin-bottom: 8px;
}
.whatmakesuscard-description {
  font-family: Poppins;
  font-size: var(--c12-10);
  font-weight: 400;
  line-height: var(--c18-15);
  text-align: left;
  color: #535b62;
}
.whatmakesusdiffContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 18px;
  margin-bottom: 102px;
  justify-items: center;
}
.whatmakesusdiffHeading {
  font-family: Metropolis;
  font-size: 32px;
  font-weight: 900;
  line-height: 32px;
  text-align: center;
  color: #2f363f;
  margin-top: 102px;
  margin-bottom: 32px;
}
@media screen and (max-width: 1250px) {
  .whatmakesusdiffHeading {
    margin-top: 96px;
    margin-bottom: 32px;
    font-family: Metropolis;
    font-size: 32px;
    font-weight: 900;
    line-height: 32px;
  }
  .whatmakesuscard {
    padding: 18px;
    width: 100%;
    height: clamp(19.25rem, 12.2579rem + 10.9145vw, 21.5625rem);
  }
  .whatmakesuscard-title {
    margin-top: 20px;
    margin-bottom: 6px;
  }
  .whatmakesusdiffContainer {
    gap: 12px;
    margin-bottom: 96px;
  }
}
@media screen and (max-width: 768px) {
  .whatmakesusdiffHeading {
    font-size: 18px;
    line-height: 18px;
    font-weight: 800;
    margin-top: 48px;
    margin-bottom: 16px;
  }
  .whatmakesusdiffContainer{
    grid-template-columns: 1fr ;
    margin-bottom: 0px;
    justify-items: center;
  }
  .whatmakesuscard{
    width: 328px;
    height: 250px;
  }
  .cardimg{
    height: 72px;
    width: 72px;
  }
  .whatmakesuscard-title{
    margin-top: 16px;
    margin-bottom: 4px;
    line-height: 21px;
    font-size: 14px;
  }
}