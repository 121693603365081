.location-map-container {
  position: relative;
}
.loation-mark-1 {
  position: absolute;
  top: 147px;
  left: 220px;
  cursor: pointer;
}
.loation-mark-2 {
  position: absolute;
  top: 244px;
  left: 332px;
  cursor: pointer;
}
.loccard-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 16px 20px;
  gap: 16px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #2f363f;
  box-shadow: 0px 4px 19.1px 1px #81878c33;
  width: 282px;
  height: max-content;
  border-radius: 5px;
  background-color: #fff;
}
.loccard-text-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
}
.addresscontainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}
.loccard-position1 {
  position: absolute;
  bottom: calc(100% + 0px);
  left: 50%;
}
.loccard-position2 {
  position: absolute;
  top: calc(100% + 0px);
  right: 50%;
}
.invisiblegap {
  width: 282px;
  height: 8px;
}
.loccard-text-row-text {
  /* width: 266px; */
  white-space: normal;
  word-wrap: break-word;
}
/* @media screen and (min-width: 1250px) and (max-width: 1360px) {
  .location-map-container {
    height:480px;
    width:400px;
  }
} */
@media screen and (max-width: 1250px) {
  .loccard-text-row-icon {
    width: 20px;
    height: 20px;
  }
  .loccard-container {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    padding: 14px 16px;
    width: 241px;
  }
  .addresscontainer {
    gap: 4px;
  }
}

@media screen and (max-width: 768px) {
  .loc-map {
    width: 328px;
    height: 211px;
  }
  .loccard-container {
    padding: 10px 6px;
    font-size: 10px;
    line-height: 15px;
    font-weight: 400;
    gap:12px;
  }
  .loccard-text-row-icon{
height: 14px;
width: 14px;
  }
  .loc-box-container-icon {
    height: 12px;
    width: 12px;
  }
  .loation-mark-1 {
    top: 54px;
    left: 157px;
  }
  .loation-mark-2 {
    top: 103px;
    left: 216px;
  }
  .loc-box-container {
    display: flex;
  }
  .invisiblegap {
    width: 141px;
    height: 8px;
  }
  .loccard-text-row {
    gap:4px;
  }
  .addresscontainer {
    gap: 2px;
  }
}
