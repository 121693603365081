.loader {
  border: 4px solid #c1b5b5;
  border-top: 4px solid #ffffff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 1250px) {
  .loader {
    height: 24px;
    width: 24px;
  }
}
