.vision-container {
  width: 100%;
  max-width: 1366px;
  padding: 0px 48px;
  margin-top: 102px;
  margin-left: auto;
  margin-right: auto;
}
.ourvision-main-container {
  width: 100%;
  padding: 0px 24px 38px 1px;

  display: flex;
  align-items: center;
  gap: 15px;
  overflow: hidden;
}
.visionheading {
  font-family: Metropolis;
  font-size: 32px;
  font-weight: 900;
  line-height: 32px;
  text-align: center;
  margin-bottom: 34px;
}
.ourvision-content {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  text-align: left;
  color: #2f363f;
  width: 760px;
}
.ourvision-banner {
  width: clamp(22.625rem, 2.0267rem + 32.1534vw, 29.4375rem);
  height: clamp(22.8125rem, 5.6158rem + 26.8437vw, 28.5rem);
}

@media screen and (max-width: 1250px) {
  .vision-container {
    margin-top: 96px;
    padding: 0 32px;
  }
  .visionheading {
    font-size: 28px;
    font-weight: 800;
    line-height: 28px;
    margin-bottom: 7px;
  }
  .ourvision-main-container {
    padding: 0;
    gap: 19px;
  }
  .ourvision-content {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
  }
}
@media screen and (max-width: 768px) {
  .vision-container {
    margin-top: 48px;
    padding: 0 16px;
  }
  .visionheading {
    font-family: Metropolis;
    font-size: 18px;
    font-weight: 900;
    line-height: 18px;
    text-align: center;
    margin-bottom: 16px;
  }
  .ourvision-content {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    text-align: center;
    width: 100%;
  }
  .ourvision-main-container {
    flex-direction: column;
    gap: 16px;
  }
  .ourvision-banner {
    height: 328px;
    width: 328px;
  }
}
