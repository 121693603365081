.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  overflow-y: scroll;
  align-items: center;
  justify-content: center;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.2);
  transition: opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 1000000;
}
.modalChild {
  overflow: auto;
  border-radius: 12px;
  height: max-content;
  width: max-content;
  display: flex;
  background: #fff;
  /* position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, -50%); */
}
