.HomePageBannerContainer {
  width: 100%;
  height: clamp(20.3125rem, -9.0809rem + 45.8824vw, 30.0625rem);
  max-width: 1366px;
  display: flex;
  margin: 0 auto;
  padding: 0 48px;
  position: relative;
}
.homebanner-text-container {
  width: clamp(23.3125rem, 12.352rem + 17.1091vw, 26.9375rem);
}
.title {
  font-family: Metropolis;
  font-size: clamp(1.75rem, 0.2382rem + 2.3599vw, 2.25rem);
  font-weight: 700;
  line-height: clamp(1.75rem, -0.8956rem + 4.1298vw, 2.625rem);
  text-align: left;
}
.subtitle {
  margin-top: 8px;
  font-family: Poppins;
  font-size: clamp(0.875rem, 0.4971rem + 0.59vw, 1rem);
  font-weight: 500;
  line-height: clamp(1.3125rem, 0.7456rem + 0.885vw, 1.5rem);
  text-align: left;
  color: #535b62;
}
.getintouchbtn {
  margin-top: 36px;
  width: 134px;
  height: 48px;
  padding: 12px 16px 12px 16px;

  border-radius: 4px;
  background-color: #1f7ae0;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}
/* .subtitle {
  margin-top: 8px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #535b62;
} */
.getintouchbtn {
  margin-top: 36px;
  width: 134px;
  height: 48px;
  padding: 12px 16px 12px 16px;
  border-color: #1f7ae0;
  border-radius: 4px;
  background-color: #1f7ae0;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  letter-spacing: 0em;
  overflow: hidden;
  box-sizing: border-box;
  border: 1px;
}
.Bannerimg {
  width: clamp(33.75rem, -16.1394rem + 77.8761vw, 50.25rem);
  height: clamp(20.3125rem, -9.1676rem + 46.0177vw, 30.0625rem);
  position: absolute;
  right: 48px;
  top: 0;
}

@media (min-width:769px) and (max-width: 1000px) {
  .Bannerimg{
    height: clamp(12.625rem, -10.8036rem + 48.8095vw, 20.3125rem);
    width:  clamp(20.5rem, -19.881rem + 84.127vw, 33.75rem);
    }

}
@media screen and (max-width: 1250px) {
  .Bannerimg {
    right: 32px;
  }
  .getintouchbtn {
    width: 109px;
    height: 37px;
    padding: 8px 10px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    margin-top: 24px;
  }
  .HomePageBannerContainer {
    padding: 0 32px;
  }
}

 
@media screen and (max-width: 768px) {
  .HomePageBannerContainer {
    display: flex;
    flex-direction: column-reverse;
    padding: 0px 16px;
    height: 400px;
    width: 360px;
    
  }

  .line-break {
    display: none;
  }
  .Bannerimg{
    height: clamp(12.625rem, -10.8036rem + 48.8095vw, 20.3125rem);
    width:  clamp(20.5rem, -19.881rem + 84.127vw, 33.75rem);
    right: unset;
  }
  .homebanner-text-container{
    width: clamp(20.5rem, -4.4524rem + 51.9841vw, 28.6875rem);
  }
  .title {
    line-height: 18px;
    font-size: 18px;
  }
  .subtitle {
    margin-top: 4px;
    line-height: 18px;
    font-size: 12px;
  }
  .getintouchbtn {
    margin-top: 12px;
    width: 92px;
    height: 32px;
    padding: 4px 8px;
    font-size: 12px;
    line-height: 24px;
    border-radius: 4px;
  }
}
