.contactUsBanner {
  overflow: hidden;
  height: 628px;
  background: linear-gradient(
    100.25deg,
    #0d0c54 0%,
    #1c21a8 43.5%,
    #1f7ae0 96.5%
  );
  width: 100%;
  border-radius: 16px;
  margin-top: 64px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
  position: relative;
}
.contactUsBanner-itemContainer {
  width: 100%;
  height: 100%;
  max-width: 1302px;
  margin: 0 auto;
  padding: 64px;
  position: relative;
}
.contactImg {
  position: absolute;
  bottom: 14px;
  right: 14px;
  z-index: 100;
}
.mapImg {
  position: absolute;
  bottom: 0;
  right: 32px;
  z-index: 50;
}
.contactUs-Text {
  width: 642px;
  display: flex;
  align-items: flex-start;

  z-index: 150;
  flex-direction: column;
}
.contactBannerTitle {
  font-family: Metropolis;
  font-size: clamp(1.75rem, 0.9941rem + 1.1799vw, 2rem);
  font-weight: 800;
  line-height: clamp(1.75rem, 0.9941rem + 1.1799vw, 2rem);
  text-align: left;
  color: #ffffff;
  margin-bottom: 12px;
}
.contactBannerSubTitle {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #ffffff;
  white-space: pre-wrap;
  white-space-collapse: preserve;
  text-wrap: wrap;
}
.getintouch {
  margin-top: 36px;
  width: 134px;
  height: 48px;
  padding: 12px 16px 12px 16px;

  border-radius: 4px;
  background-color: #ff7a00;
  font-family: Metropolis;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  letter-spacing: 0em;
  overflow: hidden;
  box-sizing: border-box;
  border: 1px;
}
.right {
  right: 0;
}
.requestdemobtn {
  width: 151px;
}
@media screen and (max-width: 1250px) {
  .right {
    width: 300px;
    height: 300px;
  }
  .contactUsBanner {
    margin-top: 32px;
    height: 560px;
  }
  .contactBannerTitle {
    margin-bottom: 8px;
  }
  .contactBannerSubTitle {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
  }
  .contactUsBanner-itemContainer {
    padding: 48px;
  }
  .getintouch {
    width: max-content;
    height: 36px;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    margin-top: 24px;
  }
  .requestdemobtn {
    width: 128px;
  }
  /* .contacticon-img {
    height:76px;
    width:76px;
  }
   */
}
@media screen and (max-width: 768px) {
  .contactUsBanner-itemContainer {
    padding: 24px 16px;
  }
  .contactUsBanner {
    margin-top: 16px;
    height: 524px;
   
  }
  .contactBannerTitle{
  font-weight: 800;
  line-height: 18px;
  font-size: 18px;
  margin-bottom: 4px;
  }
  .getintouch{
   margin-top: 18px;
   height:32px;
   font-weight: 900;
   line-height: 24px;
   font-size: 12px;
   padding: 4px 8px;
   letter-spacing: -0.3px;
  }
  .right {
   display: none;
  }
  .requestdemobtn {
    width: 104px;
  }
  
  .contactmap{
    top:0;
    right: 0;
  }
}
