.benefits-main-container {
  max-width: 1366px;
  width: 100%;
  padding: 0px 48px;
  /* height: 536px; */
  margin-top: 102px;
  margin-left: auto;
  margin-right: auto;
}
.headingbenefit {
  font-family: Metropolis;
  font-size: 32px;
  font-weight: 900;
  line-height: 32px;
  text-align: center;
  color: #2f363f;
}
.benefitcardcontainer {
  width: 100%;
  margin-top: 32px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 32px;
  column-gap: 30px;
  box-sizing: border-box;
}
.valuecardimage {
  height: clamp(3.5rem, -2.1692rem + 8.8496vw, 5.375rem);
  width: clamp(3.5rem, -2.1692rem + 8.8496vw, 5.375rem);
}

@media screen and (max-width: 1250px) {
  .benefits-main-container {
    padding: 0 32px;
    margin-top: 96px;
  }
  .headingbenefit {
    font-size: 28px;
    font-weight: 800;
    line-height: 28px;
  }
  .benefitcardcontainer {
    margin-top: 24px;
    row-gap: 24px;
    column-gap: 20px;
  }
}
@media screen and (max-width: 786px) {
  .benefitcardcontainer {
    grid-template-columns: 1fr;
    gap: 16px;
  }
  .headingbenefit {
    font-size: 18px;
    font-weight: 900;
    line-height: 18px;
  }
  .benefits-main-container {
    margin-top: 48px;
    padding: 0 16px;
  }
}
