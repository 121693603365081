.hamburgerContainer {
  position: relative;
  background-color: white;
  width: 217px;
  height: 100vh;
  border: 1px solid;
  border-color: #dde0e4;
}
.HamburgerHeader {
  justify-content: flex-end;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 8px;
  cursor: pointer;
}
.hamburgerLink {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  height: 32px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  width: 100%;
  color: #2f363f;
  text-decoration: none;
  cursor: pointer;
  margin: 0;
  box-sizing: border-box;
}

.solutionlink {
  display: flex;
}

.arrow {
  margin-right: 10px;
  align-self: center;
}
.hamburgerLinkContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.hamburgerChildLink {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
  height: 32px;
  padding: 0 10px;
  display: flex;
  gap:4px;
  align-items: center;
  width: 100%;
  color: #535B62;
  text-decoration: none;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
 .active {
  background-color: #1f7ae033 !important;
  color: #1f7ae0 !important;
} 
}