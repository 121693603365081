.career-page-main-container {
  margin: 64px 48px 102px 48px;
}
@media screen and (max-width: 1250px) {
  .career-page-main-container {
    margin: 0 32px 96px 32px;
    /* overflow: hidden; */
  }
  .careers-banner-img {
    height: 504px;
    top: 0;
    right: -86px;
  }
}
@media screen and (max-width: 768px) {
  .career-page-main-container {
  margin: 0 16px 48px 16px;
  }
  .careers-banner-img {
    width: 356px;
    top: 92px;
    right: unset;
    z-index: 0;
  }
}