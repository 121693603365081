.keycardContainer {
  /* height: max-content; */
  width: clamp(19.125rem, 0.9834rem + 28.3186vw, 25.125rem);
  border-radius: 14px;
  box-shadow: 4px 0px 36px 2px #535b622e;
  padding: 24px;
}

.keycardheading {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: #2f363f;

  margin-top: 24px;
}
.keysubheading {
  font-family: Poppins;
  font-size: var(--c12-10);
  font-weight: 400;
  line-height: var(--c18-15);
  text-align: left;
  color: #535b62;
  margin-top: 8px;
}
/* .subcontent {
  padding-left: 17px;
  width: 354px;
  height: 216px;
  margin-top: 12px !important;
} */

@media screen and (max-width: 768px) {
  .keycardimage{
    height: 76px;
    width: 76px;
  }

  .keycardheading{
    margin-top: 16px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
  }
  .keysubheading{
    margin-top: 4px;
  }
  .keycardContainer {
    height: max-content !important;
    width: 328px;
    border-radius: 14px;
    box-shadow: 4px 0px 36px 2px #535b622e;
    padding: 16px;
  }
}
