.valuecardContainer {
  height: 134px;
  width: 100%;
  border-radius: 14px;
  gap: 32px;
  padding: 24px;
  display: flex;
  box-shadow: 4px 0px 36px 2px rgba(83, 91, 98, 0.18);
}

.valuecardheading {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #2f363f;
}

.valuesubcontent {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #535b62;

  margin-top: 4px;
}
@media screen and (max-width: 1250px) {
  .valuecardContainer {
    height: 88px;
    padding: 16px;
    gap: 16px;
    border-radius: 10px;
    border: 1px solid #dde0e4;
    box-shadow: none;
  }
  .valuecardheading {
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
  }
  .valuesubcontent {
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
  }
}
@media screen and (max-width: 768px) {
  .valuecardContainer {
    gap: 10px;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 0px;
    height: 99px;

  }
  .valuecardimage {
    height: 48px;
    width: 48px;
  }
  .valuecardheading {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
  }
  .valuesubcontent {
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    text-align: left;
  }
}
