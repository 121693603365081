.slidecard-main-container {
    margin-top: 102px;
    margin-left: auto ;
    margin-right: auto ;
    height: 833px;
    width: 1270px;
  }
  .careerOpprtunities-container {
    display: flex;
    justify-content: space-between;
  }
 
  
  .careerOpprtunities-heading {
    font-family: Metropolis;
    text-align: center;
    font-size:32px;
    font-weight: 900;
    line-height: 32px;
    margin-bottom: 32px ;
    color: #2F363F;

  }
  .grid-item2 {
    /* width: calc(100% - clamp(5rem, -12.7636rem + 27.7286vw, 10.875rem)); */
    width: clamp(55rem, 8.1342rem + 73.1563vw, 70.5rem);
    position: relative;
    left: 162px;
  }
  .carrerimg {
    width: auto;
    position: absolute;
    z-index: -20;
    height: clamp(36.25rem, 0.5337rem + 55.7522vw, 48.0625rem) !important;
    width: clamp(34.375rem, 23.7924rem + 16.5192vw, 37.875rem) !important;
    border-radius: 16px ;
  }
  @media screen and (max-width: 1250px) {
    .carrerimg{
      height:580px !important;
      width:550px !important;
    }
    .slidecard-main-container {
      height:632px;
      margin-top: 96px;
    }
    .careerOpprtunities-heading {
      font-weight: 800;
      margin-bottom: 24px;
      font-size:  clamp(1.75rem, 0.9941rem + 1.1799vw, 2rem);
      line-height:  clamp(1.75rem, 0.9941rem + 1.1799vw, 2rem);
    }
    .grid-item2 {
      width:922px;
      /* width: calc(100% - clamp(5rem, -12.7636rem + 27.7286vw, 10.875rem)); */
      left: 54px !important;
    }
  }
  @media screen and (max-width: 768px) {
    .slidecard-main-container{
      margin-top: 48px;
    }
    .carrerimg {
    width: auto;
    position: absolute;
    z-index: -20;
    height:353px  !important;
    width:270px !important;
    border-radius: 16px !important;
    }
    .careerOpprtunities-heading {
      font-family: poppins;
      text-align: center;
      font-size:18px !important;
      font-weight: 800;
      line-height: 18px !important;
      margin-bottom: 10px !important;
      color: #2F363F;
      
    }
    .grid-item2 {
      /* width: calc(100% - clamp(5rem, -12.7636rem + 27.7286vw, 10.875rem)); */
      /* width: clamp(55rem, 8.1342rem + 73.1563vw, 70.5rem); */
      width: 216px;
      position: relative;
      left: 112px !important;

    }
    .careerOpprtunities-container {
      height: 282px;
    }
  }
  
  /* box-shadow: 4px 0px 36px 2px rgba(83, 91, 98, 0.18); */
  