.key-main-container {
  max-width: 1366px;
  width: 100%;
  padding: 0px 48px;

  margin-top: 102px;
  margin-left: auto;
  margin-right: auto;
}
.headingkey {
  font-family: Metropolis;
  font-size: 32px;
  font-weight: 900;
  line-height: 32px;
  text-align: center;

  color: #2f363f;
}
.keycardcontainer {
  width: 100%;
  margin-top: 32px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  row-gap: 32px;
  column-gap: 32px;

  box-sizing: border-box;
}

@media screen and (max-width: 1250px) {
  .key-main-container {
    padding: 0 32px;
    margin-top: 96px;
  }
  .headingkey {
    font-size: 28px;
    font-weight: 800;
    line-height: 28px;
  }
  .keycardcontainer {
    width: 100%;
    margin-top: 24px;
    column-gap: 21px;
    row-gap: 24px;
  }
}
@media screen and (max-width: 768px) {
  .key-main-container {
    padding: 0 16px;
    margin-top: 48px;
  }
  .headingkey {
    font-size: 18px;
    font-weight: 900;
    line-height: 18px;
  }
  .keycardcontainer {
    margin-top: 16px;
  }
  
}
