* {
  padding: 0px;
  margin: 0px;
}
.left-second {
  width: 235px;
}

.footer-main-container {
  position: relative;
  background-color: #1f7ae0;
  color: white;
  width: 100%;
  height: 405px;
  padding: 48px 48px 57px 48px;
  margin: 0 auto;
  max-width: 1366px;
}
.footer-heading {
  font-weight: 600;
  font-size: 20px;
  line-height:30px;
  margin-bottom: 22px;
}

.footer-list-container-l {
  display: flex;
  /* gap: clamp(2.25rem, -3.0413rem + 8.2596vw, 4rem); */
  gap: 48px;
  justify-content: flex-start;
  width: max-content;
}
.footer-list-container-r {
  display: flex;
  gap: clamp(2.625rem, -13.4379rem + 25.0737vw, 7.9375rem);
  justify-content: space-between;
}
.footer-list-ul {
  list-style: none;
  padding: 0%;
  margin: 0%;
}
.footer-list-li {
  cursor: pointer;
  font-weight: 400;
  font-size: clamp(0.75rem, 0.3721rem + 0.59vw, 0.875rem);
  line-height: clamp(1.125rem, 0.5581rem + 0.885vw, 1.3125rem);
  margin-bottom: 16px;
}

.footer-list-li2 {
  font-weight: 500;
  font-size: clamp(0.75rem, 0.3721rem + 0.59vw, 0.875rem);
  line-height: clamp(1.125rem, 0.5581rem + 0.885vw, 1.3125rem);
  margin-bottom: clamp(1rem, 0.2441rem + 1.1799vw, 1.25rem);
}
.footer-tag {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  position: absolute;
  bottom: 0;
 
}
.row {
  flex-wrap: nowrap !important;
  /* gap: clamp(5rem, -19.7557rem + 38.6431vw, 13.1875rem); */
  gap: 87px;

  /* justify-content: space-between; */
}
.container2 {
  /* gap: clamp(3.125rem, 0.4794rem + 4.1298vw, 4rem); */
  gap: 64px;
}

body {
  overflow-x: hidden !important;
}

.footer-list-li-head {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  margin-bottom: 16px;
}
.f-col-1 {
  flex: 6;
}
.f-col-2 {
  flex: 4;
}
@media screen and (max-width: 1080px) {
  .row > * {
    padding: 0 !important;
  }
}

a {
  all: initial;
  text-decoration: none;
}
.row {
  margin-left: 0;
  font-family: Poppins;
}
/* @media screen and (max-width: 1060px) {
    .container,
    .container-fluid,
    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm {
      padding: 0 !important;
    }
  } */
.row > * {
  padding: 0 !important;
}
.footer-list-li a {
  color: #fff !important;
  text-decoration: none !important;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
}

.address-Heading {
  font-family: Poppins;
  font-size: clamp(0.875rem, 0.4971rem + 0.59vw, 1rem);
  font-weight: 600;
  line-height: clamp(1.3125rem, 0.7456rem + 0.885vw, 1.5rem);
  text-align: left;
}

.address-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: clamp(0.25rem, -0.5059rem + 1.1799vw, 0.5rem);
}
.mobileViewConnect {
  display: none;
}
.Mobileview{
  display: none;
}
.footer-col-2-icons {
  height: 20px;
  width: 20px;
}
.footer-li-icon {
  margin-right: 4px;
}
@media screen and (min-width: 1250px) and (max-width: 1360px) {
  .row{
    gap:44px;
  }
}
@media screen and (max-width: 1250px) {
  .container-fluid{
    padding: 0px;
  }
  .footer-main-container{
    padding:48px 32px 48px 32px;
  }
  .footer-heading{
    font-size:  clamp(1rem, 0.2441rem + 1.1799vw, 1.25rem);
    line-height: clamp(1.5rem, 0.3662rem + 1.7699vw, 1.875rem);
    margin-bottom: 28px;
  }
  .footer-list-container-l{
    gap: 12px;
  }
  .row{
    gap:32px;
  }
  .container2{
    gap:32px;
  }
  .footer-tag{
    margin-bottom: 3px;
  }
  .left-second {
    width:206px;
  }
  .linkedinicon{
    height:18px;
    width: 18px;
  }

}
@media screen and (min-width: 720px) and (max-width: 1250px) {
  .footer-col-2-icons {  
    height: 18px;
    width: 18px;
  }
  .footer-li-icon {
    margin-right: 6px;
  }
}
@media screen and (max-width: 768px) {
  .row {
    flex-direction: column;
    gap: 32px;
  }
  .footer-main-container {
    height: 784px;
    padding: 16px 16px 72px 16px;
  }
  .left-first{
    width: 67px;
  }
  .left-second{
    width: 202px;
  }
  .footer-heading {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
    margin-bottom: 12px;
  }
  .footer-list-li-head {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    margin-bottom: 12px;
  }
  .footer-list-li {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    margin-bottom: 12px;
  }
  .footer-list-container-l {
    width: 328px;
    gap: 42px;
  }
  .desktop-connect {
    display: none;
  }
  .footer-list-container-r {
    flex-direction: column;
  }
  .Mobileview{
    display: flex;
    gap: 48px;
  justify-content: flex-start;
  width: max-content;
  margin-top: 24px;
    
  }
  .mobileViewConnect {
    display: flex;
    align-items: center;
    gap: 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
  }
  .footer-col-2-icons {
    height: 18px;
    width: 18px;
  }
  .container2 {
    gap: 32px;
  }
  .footer-li-icon {
    margin-right: 6px;
  }
  .footer-list-li2-last {
    margin-bottom: 0 !important;
  }
  .footer-tag {
    margin-bottom: 0;
  }
  .footer-main-container.expanded {
  height: 821.31px;
}
}
