.regtech-container {
  max-width: 1366px;
  width: 100%;
  padding: 0px 48px;
  margin-top: 102px;
  margin-left: auto;
  margin-right: auto;
}
.regtext-container {
  width: 100%;
  margin-bottom: 32px;
}
.titlereg {
  font-family: Metropolis;
  font-size: var(--c32-28);
  font-weight: 900;
  line-height: var(--c32-28);
  text-align: center;
  color: #2f363f;
}

.regcards {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 32px;
  column-gap: 30px;
  box-sizing: border-box;
}

@media screen and (max-width: 1250px) {
  .regtech-container {
    padding: 0 32px;
    margin-top: 96px;
  }
  .regcards {
    row-gap: 24px;
    column-gap: 20px;
  }
  .regtext-container {
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 768px) {
  .regtech-container {
    padding: 0 16px;
    margin-top: 48px;
  }
  .titlereg{
    line-height: 18px;
    font-size: 18px;
  }
  .regtext-container{
    margin-bottom: 16px;
  }
  .regcards {
    grid-template-columns: 1fr ;
    row-gap: 14px;
  }
}
