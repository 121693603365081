.lb-logoBanner {
  max-width: 1270px;
  width: 100%;
  padding: 2px 24px 7px 44px;
  display: flex;
  justify-content: center;
  gap: 35px;
}
.lb-logoBanner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 36px;
  color: #1f7ae0;
}

.lb-logobannertext {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
}

.lb-logobannerBtn {
  height: 56px;
  width: 134px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff7a00;
  color: white;
  border: none;
  border-radius: 6px;
  font-family: Metropolis;
  font-size: 16px;
  font-weight: 900;
  line-height: 24px;
  text-align: center;
}
.logobanner-img {
  width: clamp(26.625rem, 0.7354rem + 40.413vw, 35.1875rem);
  height: clamp(26rem, 0.4884rem + 39.823vw, 34.4375rem);
}
@media screen and (max-width: 1250px) {
  .lb-logoBanner {
    padding: 0;
    gap: 67px;
  }
  .lb-logobannertext {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }
  .lb-logoBanner-content {
    gap: 24px;
  }
  .lb-logobannerBtn {
    width: 113px;
    height: 36px;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    border-radius: 4px;
    font-family: Poppins;
  }
}
@media screen and (max-width: 768px) {
  .lb-logoBanner {
    flex-direction: column;
    gap: 17px;
    width: 328px;
    margin-right: auto;
    margin-left: auto;
   
  }
  .lb-logobannertext {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    text-align: left;
  }
  .lb-logoBanner-content {
    gap: 17px;
  }
  .lb-logobannerBtn {
    width: 92px;
    height: 32px;
    padding: 4px 8px;
    font-family: Metropolis;
    font-size: 12px;
    font-weight: 900;
    line-height: 24px;
    text-align: center;
  }
  .logobanner-img {
    width: 328px;
    height: 328px;
  }
}
