.formSuccessContainer {
  padding-top: 44px;
  height: 422px;
  width: 100%;
  position: relative;
  margin: 164px 0;
}
.BackButton {
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}
.SuccessTextContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.thanksText {
  font-family: Metropolis;
  font-size: 64px;
  font-weight: 700;
  line-height: 64px;
  text-align: left;
  color: #1f7ae0;
  margin-top: 8px;
  margin-bottom: 42px;
}
.SucessText {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
  color: #535b62;
}
.navigateToHome {
  all: initial;
  background-color: #1f7ae0;
  color: #ffffff;
  box-sizing: border-box;
  height: 36px;
  width: 146px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  margin-top: 24px;
  border-radius: 4px;
  cursor: pointer;
}
@media screen and (min-width: 768px) and (max-width: 1250px) {
  .formSuccessContainer {
    margin-top: 64px;
    margin-bottom: 142px;
    height:320px;
  }
  .sucessImg {
    height: 102px;
    width: 102px;
  }
  .thanksText {
    font-family: Metropolis;
    font-size: 40px;
    font-weight: 700;
    line-height: 40px;
    text-align: left;
    margin-top: 16px;
    margin-bottom: 12px;
  }
  .SucessText {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    margin-top: 4px;
  }
  .navigateToHome {
    margin-top: 24px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
  }
}

/* mobile */
@media screen and (max-width: 768px) {
  
  .formSuccessContainer{
  margin: 48px 0px;
  height: 332px;
  }
  .sucessImg {
    height: 82px;
    width: 82px;
  }
  .thanksText {
    font-family: Metropolis;
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    margin-top: 16px;
    margin-bottom: 4px;
  }
  .SucessText {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    margin-top: 4px;
  }
  .navigateToHome {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    width: 146px;
    height: 32px;
    margin-top: 48px;
  }
  .SuccessTextContainer {
    padding-top: 24px;
  }
}
