.card-main-container {
    width: clamp(17rem, 1.1261rem + 24.7788vw, 22.25rem);
    height: 283px;
    border-radius: 14px;
    box-shadow: 4px 0 36px 2px rgba(83, 91, 98, 0.18);
    background-color: rgba(255, 255, 255, 1);
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    margin-bottom: 36px;
    margin-top: 72px;
    /* margin-top: 40px; */
  }
 
  @media screen and (min-width: 1218px) and (max-width: 1366px) {
    .role_descitption {
      line-height: 18px !important;
    }
  }
  @media screen and (max-width: 1250px) {
    .card-main-container {
      height:246px;
      width:290px;
      padding: 24px;
      margin-bottom: 82px;
      margin-top: 87px;
    }
    .card-heading {
      margin-bottom: 12px !important;
    }
  }
  
  .card-heading {
    padding: 0%;
    margin: 0%;
    font-weight: 600;
    font-size: clamp(1rem, 0.2441rem + 1.1799vw, 1.25rem);
    line-height: clamp(1.5rem, 0.3662rem + 1.7699vw, 1.875rem);
    font-family: Poppins;
    margin-bottom: 16px;
    color: #2f363f;
  }
  .card-contain-container {
    margin-bottom: clamp(1rem, 0.2441rem + 1.1799vw, 1.25rem);
  }
  
  .role_descitption {
    color: #535b62;
    text-align: justify;
    font-family: Poppins;
    font-weight: 500;
    font-size: clamp(0.75rem, 0.3721rem + 0.59vw, 0.875rem);
    line-height: clamp(1.125rem, 0.5581rem + 0.885vw, 1.3125rem);
  }
 
  @media screen and (max-width: 768px) {
    .card-main-container {
      padding-top: 16px;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 38px;
      height: 211px;
      width: 216px;
      border-radius: 14px !important;
      margin-bottom: 8px;
      box-shadow: 4px 0px 36px 2px #535B622E;

      /* box-shadow: 2px 0 15px 1px rgba(83, 91, 98, 0.18); */
      margin-top: 38px;
    }
    .card-heading {
      padding: 0%;
      margin: 0%;
      font-weight: 600;
      font-size: 14px !important;
      line-height: 21px !important;
      font-family: Poppins;
      margin-bottom: 8px !important;
      color: #2f363f;
    }
  
    .role_descitption {
      color: #535b62;
      text-align: justify;
      font-family: Poppins;
      font-weight: 500;
      font-size: 10px !important;
      line-height: 15px !important;
    }
  }
  .tenpxspace {
    width: 30px;
    /* background: red; */
  }
  