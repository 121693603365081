.marquee {
  position: relative;
  margin-top: 72px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 445px; /* height is increased because of box -shadow  445-385 = 60 so decrease 30 y margin*/
}

.marquee-content {
  /* display: inline-block; */
  width: 200%;
  animation: marquee 35s linear infinite;
  display: flex;
  gap: 28px;
  position: absolute;
  left: 0;
}

@keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -2037px;
    /* left: -calc(200% - 100vh); */
  }
}

.marquee-content:hover {
  animation-play-state: paused;
}

@media screen and (max-width: 1250px) {
  .marquee {
    margin-top: 66px;
  }
}
@media screen and (max-width: 768px) {
  .marquee {
    margin-top: 28px;
    height: 364px;
  }
  .marquee-content {
    gap: 14px;
  }
}

