.aboutus-main-container {
}
.aboutusbannerimg {
  padding: 0px 48px;
}
.logo-bannerabout {
  padding: 0px 48px;
  margin: 102px auto;
  max-width: 1366px;
  width: 100%;
}
.aboutus-banner-img {
  right: 0;
  bottom: 32px;
}
@media screen and (max-width: 1250px) {
  .aboutus-banner-img {
    right: 0;
    bottom: 57px;
    height: 446px;
    width: 456px;
  }
  .aboutusbannerimg {
    padding: 0px 32px;
  }
  .logo-bannerabout {
    padding: 0px 32px;
    margin: 96px auto;
  }
}
@media screen and (max-width: 768px) {
  .aboutus-banner-img {
    right: 0;
    bottom: 0;
    height: 200px;
    width: 200px;
  }
  .aboutusbannerimg {
    padding: 0px 16px;
  }
  .logo-bannerabout {
    padding: 0px 16px;
    margin: 48px auto;
  }
}
