.choose-evonext-main-container {
  width: 100%;
  max-width: 1366px;
  padding: 0px 48px;
  margin-top: 102px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.choose-evonext-content {
  width: 626px;
  height: 72px;
  font-family: Poppins;
  font-size: clamp(1.25rem, 0.4941rem + 1.1799vw, 1.5rem);
  font-weight: 700;
  line-height: clamp(1.875rem, 0.7412rem + 1.7699vw, 2.25rem);
  text-align: left;
  color: #1f7ae0;
  margin-left: 44px;
}

.choose-evonext-banner {
  /* width: 622px;
    height: 609px; */
  top: -25px;
  left: 670px;
  width: clamp(26.625rem, -2.4771rem + 45.4277vw, 36.25rem);
  height: clamp(26rem, -1.2124rem + 42.4779vw, 35rem);
}
@media screen and (max-width: 1250px) {
  .choose-evonext-main-container {
    padding: 0 32px;
    margin-top: 96px;
  }
  .choose-evonext-content {
    margin-left: 0;
    width:520px;
    height:60px;
  }
}
@media screen and (max-width: 768px) {
  .choose-evonext-main-container {
    padding: 0 16px;
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    gap:17px;
  }
  .choose-evonext-content {
    width:328px;
    height:81px;
    line-height: 27px;
    font-size: 18px;
  }
  .choose-evonext-banner {
    height: 328px;
    width: 328px;
  }
}
