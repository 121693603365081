.sol-textimgBanner-container {
  position: relative;
  overflow: hidden;
}

.sol-bannerimg {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.sol-textbanner-textcontainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.sol-textbanner-text-child {
  height: 100%;
  width: 100%;
  font-family: Poppins;
  font-size: var(--c16-14);
  font-weight: 400;
  line-height: var(--c24-21);
  text-align: left;
  color: #535b62;
}
.sol-textbanner-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 151px;
  height: 48px;
  background-color: #1f7ae0;
  border-radius: 4px;
  color: #ffffff;
  font-family: Metropolis;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  margin-top: 24px;
  padding: 12px 16px;
}
.sol-textbanner-heading {
  color: #2f363f;
  font-family: Metropolis;
  font-size: var(--c24-20);
  font-weight: 800;
  line-height: var(--c24-20);
  text-align: left;
  margin-bottom: 12px;
}
.adjustment-container {
  margin: 102px auto;
  width: 100%;
  max-width: 1366px;
  padding: 0 48px;
}
@media screen and (max-width: 1250px) {
  .adjustment-container {
    margin: 96px auto;
    width: 100%;
    padding: 0 32px;
  }
  .sol-textbanner-btn {
    width: 128px;
    height: 36px;
    padding: 8px 12px;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
  }
}
@media screen and (max-width: 768px) {
  .adjustment-container {
    margin: 48px auto;
    width: 100%;
    padding: 0 16px;
  }
  .sol-textbanner-heading {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 8px;
  }
  .sol-textbanner-text-child{
    font-size: 12px;
    line-height: 18px;
  }
  .sol-bannerimg {
    top:unset;
    bottom: 0;
    width: 328px !important;
    height: 257px !important;
  }
  .sol-textbanner-btn {
    width: 104px;
    height: 32px;
    padding: 4px 8px;
    margin-top: 16px;
    font-size: 12px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: -0.3px;
  }
}
