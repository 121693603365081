.cardContainer {
  height: clamp(22.3125rem, 2.0922rem + 31.5634vw, 29rem);
  width: clamp(19.125rem, 0.9834rem + 28.3186vw, 25.125rem);
  border-radius: 14px;
  box-shadow: 4px 0px 36px 2px #535b622e;
  padding: 24px;
}

.cardheading {
  font-family: Poppins;
  font-size: clamp(0.75rem, 0.3721rem + 0.59vw, 0.875rem);
  font-weight: 600;
  line-height: clamp(1.125rem, 0.5581rem + 0.885vw, 1.3125rem);
  text-align: left;
  color: #2f363f;

  margin-top: clamp(1rem, -0.5118rem + 2.3599vw, 1.5rem);
}
.cardsubheading {
  font-family: Poppins;
  font-size: clamp(0.625rem, 0.2471rem + 0.59vw, 0.75rem);
  font-weight: 400;
  line-height: clamp(0.9375rem, 0.3706rem + 0.885vw, 1.125rem);
  text-align: left;
  color: #535b62;
  margin-top: clamp(0.25rem, -0.1279rem + 0.59vw, 0.375rem);
}
.subcontent {
  padding-left: 17px;
  /* width: 354px;
  height: 216px; */
  margin-top: 12px !important;
}

.cardimage {
  width: clamp(4.5rem, -2.681rem + 11.2094vw, 6.875rem);
  height: clamp(4.5rem, -2.681rem + 11.2094vw, 6.875rem);
}
@media screen and (max-width: 1250px) {
  .subcontent {
    margin-top: 16px !important;
  }
}
@media screen and (max-width: 768px) {
  .cardContainer {
    width: 328px;
    height:383px;
    padding: 24px;
  }
  .cardheading{
  margin-top: 24px;
  }
  .subcontent{
    margin-top: 8px !important ;
  }
}
