.FindOutHelpForm-main-container {
  margin-top: 102px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1366px;
  width: 100%;
  padding: 0px 48px;
  display: flex;
  justify-content: center;
  gap: clamp(2.125rem, -1.2765rem + 5.3097vw, 3.25rem);
}

.FindOutHelpForm-form {
  width: auto;
}

.FindOutHelpForm-content {
  margin-top: 0px;
  font-family: Metropolis;
  font-size: var(--c32-28);
  font-weight: 800;
  line-height: var(--c32-28);
  text-align: left;
  color: #2f363f;
}
@media screen and (max-width: 1250px) {
  .FindOutHelpForm-main-container {
    padding: 0 32px;
    margin-top: 96px;
  }
}
@media screen and (max-width: 768px) {
  .FindOutHelpForm-main-container {
    padding: 0 0;
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    gap:24px;
    width: 328px;
  }
  .FindOutHelpForm-content{
   font-weight: 800;
   line-height: 18px;
   font-size: 18px;
   text-align: center;
  }
}
