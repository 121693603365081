.header-container {
  width: 100%;
  background-color: #1f7ae0;
  height: 72px;
  position: fixed;
  top: 0;
  z-index: 10000;
}
.header-content {
  margin: 0 auto;
  padding: 0 48px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 100%;
}
ul.header-links,
li.header-link {
  margin: 0;
  padding: 0;
  list-style: none;
}
.header-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: clamp(0.75rem, -2.2736rem + 4.7198vw, 1.75rem);
}

.solutionNavHeading {
  display: flex;
  align-items: center;
  gap: 4px;
}
.header-link {
  box-sizing: border-box;
  padding: 12px 16px;
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  line-height: 16px;
  cursor: pointer;
  position: relative;
  font-family: Metropolis;
  border-radius: 4px;
  text-decoration: none;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
}
.active {
  background-color: #ffffff;
  color: #1f7ae0;
  line-height: 16px;
  /* text-decoration: underline;
    text-underline-offset: 7px; */
}
.header-link:hover {
  /* text-decoration: underline;
    text-underline-offset: 7px; */
}
.header-logo {
  font-family: Metropolis;
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  color: #fff;
  display: flex;
  align-items: center;
}
body {
  margin: 0;
}

.hamburger-icon {
  position: relative;
  display: none;
  place-items: center;
  cursor: pointer;
}
.deprontoLogo {
  width: 140.19px;
  height: 42px;
}
.header-shadow {
  height: 64px;
}
/* mobile view */
.hamburger {
  display: none;
}
.hamburgerMenuPosition {
  display: none;
}
@media screen and (max-width: 1000px) {
  .hamburger-icon {
    display: grid;
  }
  .header-links {
    display: none;
  }
  .header-container {
    height: 56px;
  }
  .header-content {
    padding: 12px 16px;
    /* align-items: center; */
  }
  .deprontoLogo {
    width: 80px;
    height: 24px;
  }
  .header-shadow {
    height: 56px;
  }
  .hamburger {
    display: block;
  }
}
.header-menu-link {
  box-sizing: border-box;
  text-decoration: none;
  width: 136px;
  height: 32px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #535b62;
  padding: 7px 24px 7px 12px;
  cursor: pointer;
}
.header-menu-link-container {
  height: 128px;
  width: 136px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 1px 10px 0px #00000026;
  border-radius: 6px;
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #ffffff;
}

.header-menu-link:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.req-demo-header {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  padding: 12px 10px;
  background-color: #ff7a00;
  color: #ffffff;
  border-radius: 4px;
}
.solution-nav-container {
  height: 180px;
  width: 280px;
  box-shadow: 0px 1px 10px 0px #00000026;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;

}
.solution-nav {
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  padding: 0 23px 0 12px;
  box-sizing: border-box;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #535b62;
  text-decoration: none;
}
.solution-nav:hover {
  background: #e7e9ea;
  cursor: pointer;
}
.SolContainer {
  position: relative;
  height: 40px;
  width: 126px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.soln-dropdown-container {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
}

/* 1024res breakpoint  = 1250px */
@media screen and (min-width: 1250px) and (max-width: 1360px) {
  .header-link {
    width: auto !important;
  }
}
@media screen and (max-width: 1250px) {
  .header-logo {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
  }
  .header-link {
    height: 30px;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    padding: 8px 10px;
  }
  .header-content {
    padding: 0 32px;
  }
  .header-container {
    height: 61px;
  }
  .req-demo-header {
    height: 37px;
    padding: 8px 10px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: center;
    width: 137px !important;
  }
  .SolContainer {
    height: 30px;
    width: 102px !important;
  }
  .solndropdown-icon {
    height: 14px;
    width: 14px;
  }
}

@media screen and (max-width: 1000px) {
  .header-links {
    display: none;
  }
  .header-content {
    padding: 12px 16px;
    align-items: center;
    height: 54px;
  }
  .header-logo {
    font-family: Metropolis;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
  }
  .hamburgerMenuPosition {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
  }
  .header-container {
    height: 54px;
  }
}
