.slideshow-card-container {
  height: 385px;
  width: 372px;
  border-radius: 14px;
  box-shadow: 4px 0px 36px 2px #535b622e;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  display: flex;
}
.slideShowCard-heading {
  margin-top: 24px;
  margin-bottom: 4px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: #2f363f;
  white-space: break-spaces;
}
.slideShowCard-content {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #535b62;
  white-space: break-spaces;
}
@media screen and (max-width: 768px) {
.slideshow-card-container{
height: 324px;
width: 320px;
padding: 16px;
}
.imgslider{
  width: 288px !important;
  height: 159px !important;
}
.slideShowCard-heading{
  margin-top: 16px;
  margin-bottom: 6px;
  line-height: 18px;
  font-size: 12px;
}
.slideShowCard-content{
   font-size: 10px;
   line-height: 15px;
}
}