.container-title {
  font-family: Metropolis;
  font-size: var(--c32-28);
  font-weight: 900;
  line-height: var(--c32-28);
  text-align: left;
  color: #2f363f;
  width: 455px;
}
.offieloc-names {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: #535b62;
  margin-top: 32px !important;
  margin-bottom: 62px !important;
}
.location-contaner {
  width: 512px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.main-contact-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 31px;
  margin: 64px auto 164px auto;
}
.form-contaner {
  width: 727px;
  display: flex;
  flex-direction: column;
  gap: 64px;
  align-items: flex-start;
}

ol,
ul,
dl {
  margin: 0 !important;
}
.contact-page-container {
  padding: 0 48px;
}
@media screen and (min-width: 1250px) and (max-width: 1360px) {
  .location-contaner {
    width: 450px;
  }
}
@media screen and (max-width: 1250px) {
  .contact-page-container {
    padding: 0 32px;
  }
}
@media screen and (min-width: 1250px) and (max-width: 1360px) {
  .container-title {
    font-weight: 800;
  }
  .offieloc-names {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    margin-top: 25px !important;
    margin-bottom: 45px !important;
  }
  .main-contact-container {
    gap: 13px;
  }
  .form-contaner {
    width: 700px;
  }
}
@media screen and (max-width: 1250px) {
  .container-title {
    font-weight: 800;
    width: 396px;
    height: 68px;
  }
  .offieloc-names {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    margin-top: 25px !important;
    margin-bottom: 45px !important;
  }
  .main-contact-container {
    gap: 13px;
    margin-bottom: 142px;
  }
  .form-contaner {
    width: 435px;
    gap: 48px;
  }
}

@media screen and (max-width: 768px) {
  .form-contaner {
    width: 328px;
    margin-left: auto;
    margin-right: auto;
  }
  .location-contaner {
    width: unset;
    margin-left: auto;
    margin-right: auto;
  }
  .main-contact-container {
    flex-direction: column;
    gap: 93px;
    margin-top: 48px;
    margin-bottom: 104px;
  }
  .contact-page-container {
    padding: 0 16px;
  }
  .container-title {
    font-size: 18px;
    font-weight: 800;
    line-height: 20px;
    text-align: left;
    width: 100%;
    height: 18px;
  }
  .container-title-break {
    display: none;
  }
  .offieloc-names {
    margin-top: 10px !important;
    margin-bottom: 48px !important;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    padding-left: 16px;
  }
}
