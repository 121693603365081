.HomePageContainer {
  margin-top: clamp(3.3125rem, -5.9473rem + 14.4543vw, 6.375rem);
  margin-bottom: 102px;
}
@media screen and (max-width: 768px) {
  .HomePageContainer {
   margin-top: 39px;
   margin-bottom: 48px;
  }
}
