.grc-mainContainer {
  width: 100%;
  max-width: 1366px;
  padding: 0px 48px;
  height: 443px;
  margin-top: 102px;
  margin-left: auto;
  margin-right: auto;
}
.section1text {
  width: 100%;
}

.line-break{
  display:none;
}
.heading-grc {
  font-family: Metropolis;
  font-size: 32px;
  font-weight: 900;
  line-height: 32px;
  text-align: center;
  color: #2f363f;
}
.subcontent-grc {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #535b62;
  margin-top: 24px;
}
.subheading-grc {
  margin-top: 64px;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  text-align: center;
  color: #ff7a00;
}
.grcbelow {
  margin-top: 56px;
  display: flex;
  gap: 78px;
  padding: 0 64px;
  height: max-content;
}
.section1,
.section2 {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  text-align: left;
  color: #2f363f;
}

.numbersection {
  font-family: Metropolis;
  font-size: 48px;
  font-weight: 900;
  line-height: 48px;
  text-align: left;
  color: #1f7ae0;
}
.section1 {
  width: clamp(19.6875rem, -26.9893rem + 72.8614vw, 35.125rem);
}
@media screen and (max-width: 1250px) {
  .grc-mainContainer {
    padding: 0px 32px;
    margin-top: 96px;
    height: 445px;
  }
  .heading-grc {
    font-size: 28px;
    line-height: 28px;
    font-weight: 800;
  }
  .subcontent-grc {
    margin-top: 24px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }
  .subheading-grc {
    margin-top: 48px;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
  }
  .numbersection {
    line-height: 40px;
    font-size: 40px;
  }
  .section1,
  .section2 {
    font-size: 28px;
    line-height: 42px;
  }
}
@media screen and (max-width: 768px) {
  .grc-mainContainer {
    padding: 0px 16px;
    margin-top: 48px;
    height: max-content;
  }


  .section1,
  .section2 {
    font-size: 20px;
    line-height: 30px;
  }
  .numbersection {
    line-height: 32px;
    font-size: 32px;
  }
  .line-break{
    display:block;
  }
  .heading-grc {
    font-family: Metropolis;
    font-size: 18px;
    font-weight: 900;
    line-height: 18px;
    text-align: center;
  }
  .subcontent-grc {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    margin-top: 16px;
  }
  .subheading-grc {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: center;
    margin-top: 32px;
    width: 328px;
    margin-left: auto;
    margin-right: auto;
  }
  .grcbelow {
    padding: 0;
    flex-direction: column;
    padding-left: 16px;
    gap: 78px;
    margin-top: 48px;
    width: 336px;
    margin-right: auto;
    margin-left: auto;
  }
}
