.ourcardContainer {
  height: clamp(39.75rem, 20.8525rem + 29.4985vw, 46rem);
  width: 100%;
  border-radius: 24px;
  box-shadow: 0px 4px 52.2px 11px #adb1b552;
  background-color: #ffffff;
  padding: 48px 0 0 48px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  overflow: hidden;
}
.textsection {
  z-index: 10;
  grid-area: 1/1;
}

.ourcardheadingcontent {
  max-width: 718px;
}
.ourtitlecard {
  font-family: Metropolis;
  font-size: var(--c24-20);
  font-weight: 800;
  line-height: var(--c24-20);
  text-align: left;
  color: #1f7ae0;
}
.oursubtitlecard {
  margin-top: 8px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #535b62;
}
.subheadingtext {
  font-family: Poppins;
  font-size: clamp(1rem, 0.2441rem + 1.1799vw, 1.25rem);
  font-weight: 700;
  line-height: clamp(1.5rem, 0.3662rem + 1.7699vw, 1.875rem);
  text-align: left;
  color: #2f363f;
}
.ourcardsubheading {
  font-family: Poppins;
  font-size: var(--c14-12);
  font-weight: 500;
  line-height: var(--c21-18);
  text-align: left;
  color: #535b62;
  margin-top: 14px;
}
li.ourcardsubheading:first-child {
  margin-top: 8px;
}
.oursubcontent {
  width: 474px;
  padding-left: 17px;
  margin-top: 8px;
  margin-bottom: 0px;
}

.ourcardsubheading {
  /* display: block; */
  /* margin-left: 20px;  */
}
.textimageourcontainers {
  display: flex;
}
.textourcontainer {
  margin-top: 36px;
  width: 476px;
}
.knowmorebtn {
  margin-top: 24px;
  width: 122px;
  height: 48px;
  padding: 12px 16px 12px 16px;
  border-color: #1f7ae0;
  border-radius: 4px;
  background-color: #1f7ae0;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;

  color: #ffffff;
  letter-spacing: 0em;
  overflow: hidden;
  box-sizing: border-box;
  border: 1px;
}
.ourcardimage {
  grid-area: 1/1;
  justify-self: end;
  align-self: end;
}
@media screen and (max-width: 1250px) {
  .ourcardsubheading {
    margin-top: 8px;
  }
  .knowmorebtn {
    margin-top: 24px;
    width: 99px;
    height: 37px;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    padding: 8px 10px;
  }
  .textourcontainer {
    width: 375px;
  }
  .oursubcontent {
    width: 375px;
  }
  .oursubtitlecard {
    font-size: 14px;
    line-height: 21px;
  }
}
@media screen and (max-width: 768px) {
  .ourcardContainer {
  
    padding: 24px 16px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
  }
  .ourtitlecard {
    font-size: 14px;
    line-height: 14px;
  }
  .oursubtitlecard {
    font-size: 10px;
    line-height: 15px;
    margin-top: 4px;
  }
  .ourcardheadingcontent {
    max-width: 296px;
  }
  .textourcontainer {
    margin-top: 24px;
    width: 296px;
  }
  .subheadingtext {
    font-size: 12px;
    line-height: 18px;
  }
  .oursubcontent {
    width: 296px;
  }
  .ourcardsubheading {
    font-size: 10px;
    line-height: 15px;
    margin-top: 4px;
    letter-spacing: -0.2px;
  }
  li.ourcardsubheading:first-child {
    margin-top: 6px;
  }
  .knowmorebtn {
    margin-top: 36px;
    width: 82px;
    height: 24px;
    padding: 7px 8px;
    border-radius: 4px;
    font-family: Metropolis;
font-size: 10px;
font-weight: 900;
line-height: 10px;


  }
  .knowother{
    margin-top: 24px;
    width: 82px;
    height: 24px;
    padding: 7px 8px;
    border-radius: 4px;
    font-family: Metropolis;
font-size: 10px;
font-weight: 900;
line-height: 10px;
border-color: #1f7ae0;
border-radius: 4px;
background-color: #1f7ae0;
color: #ffffff;
letter-spacing: 0em;
overflow: hidden;
box-sizing: border-box;
border: 1px;
  }
 
}
