.ourvalues-main-container {
  width: 100%;
  max-width: 1366px;
  padding: 0px 48px;
  margin-top: 102px;
  margin-left: auto;
  margin-right: auto;
}
.heading {
  font-family: Metropolis;
  font-size: 32px;
  font-weight: 900;
  line-height: 32px;
  text-align: center;
}
.valuecards {
  width: 100%;
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 32px;
  column-gap: 30px;
  box-sizing: border-box;
}
@media screen and (max-width: 1250px) {
  .ourvalues-main-container {
    padding: 0 32px;
    margin-top: 96px;
  }
  .heading {
    font-size: 28px;
    font-weight: 800;
    line-height: 28px;
  }
  .valuecards {
    margin-top: 24px;
    column-gap: 20px;
    row-gap: 24px;
  }
}
@media screen and (max-width: 768px) {
  .valuecards {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
  }
  .ourvalues-main-container {
    padding: 0 16px;
    margin-top: 48px;
  }
  .heading {
    font-size: 18px;
    font-weight: 900;
    line-height: 18px;
    text-align: center;
  }
}
