.CareerJourney-main-container {
  margin-top: 102px;
  margin-left: auto !important;
  margin-right: auto !important;
  padding: 0px !important;
  margin-bottom: 102px;
}

.CareerJourney-header {
  font-family: Poppins;
  font-size: 32px;
  font-weight: 900;
  line-height: 48px;
  text-align: center;
  width: auto;
  height: 48px;
}

.CareerJourney-content {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  justify-content: center;
  text-align: center;
  color: #2f363f;
  width: 100%;
  height: 21px;
  margin-top: 16px;
}

.CareerJourney-banner {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 152px;
  background: linear-gradient(
    90.82deg,
    #f58c2b 0%,
    #ed9e56 46.67%,
    #ff7a00 98.4%
  );
  margin-top: 21px;
}

.CareerJourney-banner-content {
  margin: auto;
  font-family: Metropolis;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
  height: 24px;
}

@media screen and (max-width: 1250px) {
  .CareerJourney-header {
    font-size: 28px;
    font-weight: 800;
    line-height: 42px;
  }
  .CareerJourney-content {
    margin-top: 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }
  .CareerJourney-banner-content {
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
  }
  .CareerJourney-banner {
    height: 108px;
  }
  .CareerJourney-main-container {
    margin-top: 96px;
    margin-bottom: 96px;
  }
}
@media screen and (max-width: 768px) {
  .CareerJourney-header {
    font-size: 18px;
    line-height: 27px;
  }
  .CareerJourney-content{
    font-size: 12px;
    line-height: 18px;
    height: 54px;

  }
  .CareerJourney-banner-content{
font-weight: 600;
font-size: 14px;
line-height: 16px;
text-align: center;
  }
  .CareerJourney-main-container{
    margin-bottom: 48px;
    margin-top: 192px;
  }
}

