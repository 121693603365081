.regcardContainer {
  height: clamp(5.5rem, -0.1692rem + 8.8496vw, 7.375rem);
  width: 100%;
  border-radius: 10px;
  border: 1px solid #dde0e4;
  padding: 24px;
  display: flex;
}
.regtext {
  text-align: left;
  margin-left: 24px;
}
.regcardheading {
  font-family: Poppins;
  font-size: var(--c20-14);
  font-weight: 700;
  line-height: var(--c30-21);

  color: #2f363f;
}

.regsubcontent {
  font-family: Poppins;
  font-size: var(--c12-10);
  font-weight: 500;
  line-height: var(--c18-15);
  text-align: left;
  color: #535b62;
  margin-top: 4px;
}
.regcardimage {
  height: clamp(3.5rem, 1.9882rem + 2.3599vw, 4rem);
  width: clamp(3.5rem, 1.9882rem + 2.3599vw, 4rem);
}

@media screen and (max-width: 1250px) {
  .regcardContainer {
    padding: 16px;
  }
  .regtext {
    margin-left: 16px;
  }
}
@media screen and (max-width: 768px) {
  .regcardContainer{
    height: 102px;
  }
  .regcardimage {
    height: 48px;
    width: 48px;
  }
  .regcardheading{
    font-size: 12px;
    line-height: 18px;
  }
  .regtext{
    margin-left: 12px;
  }
}
