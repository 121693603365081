.successPopupContainer {
  width: 532px;
  height: 210px;
  padding: 0 24px;
  border-radius: 12px;
  position: relative;
  box-shadow: 0px 8px 36px 0px rgba(0, 0, 0, 0.16);
}
.successPopupContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-top: 72px;
}
.successPopUp-close {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}
.successpopup-text {
  color: #2f363f;
  font-family: Poppins;
  font-size: clamp(0.75rem, 0.3721rem + 0.59vw, 0.875rem);
  font-weight: 600;
  line-height: clamp(1.125rem, 0.5581rem + 0.885vw, 1.3125rem);
  text-align: center;
}

@media screen and (max-width: 1250px) {
  .successPopupContainer {
    height: 174px;
  }
}
@media screen and (max-width: 768px) {
  .successPopupContainer {
    width: 328px;
    height: 231px;
    margin: 0 auto;
  }
  .successimg{
    /* margin-top: 72px; */
  }
  .successpopup-text{
    font-size: 14px;
    line-height: 21px;
  }
}

