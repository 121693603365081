.button {
  border: none;
  padding: 0%;
  margin: 0%;
  border-radius: 100%;
}
.arrowButton {
  display: flex;
  justify-content: end;
padding-right: 12px;
  gap: clamp(0.75rem, -0.5728rem + 2.0649vw, 1.1875rem);
}
.container-fluid.slidecard-main-container {
  /* height: 833px; */
  padding: 0 !important;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 1270px;
}
.arrow-svg {
  height: clamp(1.5rem, 0.0073rem + 2.3324vw, 2rem);
  width: clamp(1.5rem, 0.0073rem + 2.3324vw, 2rem);
  /* height: clamp(1.25rem, -1.0177rem + 3.5398vw, 2rem);
    width: clamp(1.25rem, -1.0177rem + 3.5398vw, 2rem); */
  /* font-size: clamp(1.5rem, 0.0073rem + 2.3324vw, 2rem); */
}
/* .SliderBox {
  width: 1128px;
} */
@media (max-width: 1367px) {
  .arrow-svg {
    height: 32px;
    width: 32px;
  }
}
@media (max-width: 1250px) {
  .container-fluid.slidecard-main-container{
    width:960px;
  }
  .SliderBox {
    width: 922px;
  }
  .arrow-svg {
    height: 32px;
    width: 32px;
    
  }
  .arrowButton {
    gap: 19px;
    }
}
@media screen and (max-width: 768px) {
  .arrow-svg {
    height: 20px;
    width: 20px;
  }

  .arrowButton {
    width: 216px;
    gap: 8px;
    padding-right: 0px;
  }
  .SliderBox {
    width:232px;
  }

  .container-fluid.slidecard-main-container {
    height: 230px !important;
    padding-left: 0px;
    padding-right: 0px;
    width: 328px;
  }
}

.slick-prev:before {
  content: "." !important;
  display: none;
}
.slick-arrow {
  display: none !important;
}
