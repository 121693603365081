body {
  margin: 0;
}
html,
body {
  scroll-behavior: auto !important;
}

.below1366 {
  display: none;
}

@media screen and (max-width: 999px) {
  .from1366 {
    display: none;
  }
  .below1366 {
    display: block;
  }
}
body::-webkit-scrollbar {
  display: none;
}

/* definig variables  */

:root {
  --c12-10: clamp(0.625rem, 0.2471rem + 0.59vw, 0.75rem);
  --c18-15: clamp(0.9375rem, 0.3706rem + 0.885vw, 1.125rem);
  --c20-14: clamp(0.875rem, -0.2588rem + 1.7699vw, 1.25rem);
  --c30-21: clamp(1.3125rem, -0.3883rem + 2.6549vw, 1.875rem);
  --c14-12: clamp(0.75rem, 0.3721rem + 0.59vw, 0.875rem);
  --c16-14: clamp(0.875rem, 0.4971rem + 0.59vw, 1rem);
  --c21-18: clamp(1.125rem, 0.5581rem + 0.885vw, 1.3125rem);
  --c24-21: clamp(1.3125rem, 0.7456rem + 0.885vw, 1.5rem);
  --c32-28: clamp(1.75rem, 0.9941rem + 1.1799vw, 2rem);
  --c24-20: clamp(1.25rem, 0.4941rem + 1.1799vw, 1.5rem);
  --c64-48: clamp(3rem, -0.0236rem + 4.7198vw, 4rem);
}
.header-space {
  height: 72px;
}
@media screen and (max-width: 1250px) {
  .header-space {
    height: 61px;
  }
}
@media screen and (max-width: 768px) {
  .header-space {
    height: 54px;
  }
}
