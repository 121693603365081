.whychooseus-container {
  width: 100%;
  max-width: 1366px;
  padding: 0px 48px;
  /* height: 1080px; */
  margin-top: 72px;
  margin-left: auto;
  margin-right: auto;
}
.text-container {
  width: 100%;
  height: 88px;
}
.titlewhy {
  font-family: Metropolis;
  font-size: clamp(1.75rem, 0.9941rem + 1.1799vw, 2rem);
  font-weight: 900;
  line-height: clamp(1.75rem, 0.9941rem + 1.1799vw, 2rem);
  text-align: center;
  color: #2f363f;
}
.whysubtitle {
  margin-top: 8px;
  font-family: Poppins;
  font-size: clamp(0.875rem, 0.4971rem + 0.59vw, 1rem);
  font-weight: 500;
  line-height: clamp(1.3125rem, 0.7456rem + 0.885vw, 1.5rem);
  text-align: center;
  color: #535b62;
}
.whychoosecards {
  width: 100%;
  /* overflow-y: auto; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: clamp(1.3125rem, -0.7662rem + 3.2448vw, 2rem);
  column-gap: clamp(1.5rem, -0.0118rem + 2.3599vw, 2rem);
  box-sizing: border-box;
  margin-top: clamp(1.5rem, -0.0118rem + 2.3599vw, 2rem);
}
@media screen and (max-width: 1250px) {
  .titlewhy {
    font-weight: 800;                                   
  }
  .whychooseus-container {
    margin-top: 66px;
    /* outline:solid;
    outline-color: black; */
    padding: 0 32px;
  }
}

 @media (min-width:769px) and (max-width: 1000px){
  .whychoosecards {
    grid-template-columns: repeat(2, 1fr); 
    justify-items:center; 
    gap:24px;
    /* outline: solid;
    outline-color: blue;  */
  }
}

@media screen and (max-width: 768px) {
  .whychooseus-container {
    margin-top: 32px;
    padding: 0 16px;
  }
  .titlewhy {
    font-weight: 900;
    font-size: 18px;
    line-height: 18px;
  }
  .whysubtitle{
    margin-top: 4px;
    font-size: 10px;
    line-height: 15px;
  }
  .whychoosecards {
    grid-template-columns: 1fr;
    margin-top:24px;
    row-gap: 24px;
    justify-items:center;
  }
  .text-container {
    height:97px;
  }
  .whychooseus-container {
    width: 100%;
    max-width: max-content;
    margin-left: auto;
   
    margin-right: auto;
  }
}
