
 
.copybutton {
  margin-top: 2px;
  width: 92px;
  height: 36px;
  border-radius: 6px;
  background-color: #ffffff;
  padding: 6px 12px;
  color: #535b62;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  border: none;
  
}
.copytxt {
  position: relative;
}
.copytxt::after {
  content: url(./assets/blankcopyicon.svg);
  position: absolute;
  right: -24px;
  top: 1px;
}

.copybutton:hover .copytxt::after {
  content: url(./assets/filledcopyicon.svg);
}
