/* element.style{
    padding: 0px;
} */
.oursolution-container {
  width: 100%;
 
  margin-top: 102px;
  background-color: #1f7ae01a;
  flex-direction: column;
  display: flex;
  padding: 0px 48px;
  padding-bottom: 62px;
  margin-left: auto;
  margin-right: auto;
}
.ourtext-container {
  width: 100%;
  height: 112px;
  margin-top: 62px;
  margin-bottom: 64px;
  max-width: 1270px;
  margin-left: auto;
  margin-right: auto;
}
.titleour {
  font-family: Metropolis;
  font-size: clamp(1.75rem, 0.9941rem + 1.1799vw, 2rem);
  font-weight: 900;
  line-height: clamp(1.75rem, 0.9941rem + 1.1799vw, 2rem);
  text-align: center;
  color: #2f363f;
}
.oursubtitle {
  margin-top: 8px;
  font-family: Poppins;
  font-size: var(--c16-14);
  font-weight: 500;
  line-height: var(--c24-21);
  text-align: center;
  color: #535b62;
}
.oursolutioncards {
  width: 100%;

  /* overflow-y: auto; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;

  row-gap: var(--c64-48);

  box-sizing: border-box;
}
@media screen and (max-width: 1250px) {
  .oursolution-container {
    margin-top: 96px !important;
    padding: 0 32px;
    padding-bottom: 48px;
  }
  .ourtext-container {
    margin: 48px 0;
    height:99px;
  }
  .titleour {
    font-weight: 800;
  }
  
}
@media screen and (max-width: 768px) {
  .oursolution-container {
    margin-top: 48px !important;
    padding: 0 16px;
    padding-bottom: 32px;
  }
  .ourtext-container {
    margin-top: 32px;
    height: 112px;
    margin-bottom: 24px;
  }
  .titleour {
    line-height: 18px;
    font-size: 18px;
  }
  .oursubtitle{
    margin-top: 4px;
    line-height: 15px;
    font-size: 10px;
  }
  .oursolutioncards{
row-gap: 16px;
  }
}
